<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="d-block d-md-flex justify-content-between">
        <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
          <li>
            <router-link to="/solutions/digital-poem/model-results">
              <span>Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/digital-poem/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'Attribution'">
            Attribution
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'Future Planning'">
            Future Planning
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'ROI Summary'">
            ROI Summary
          </li>
        </ul>
        <div v-if="activeTab !== 'Future Planning'" class="d-flex">
          <div class="" style="height: 40%">
            <div class="w-100 py-4 pr-0">
              <w-white-dropdown
                :options="marketDropdown"
                :selectedOption="selectedMarket"
                :labelText="'Model'"
                class="mr-3"
                :placeHolder="selectedMarket.text"
                @input="selectMarket($event)"
              ></w-white-dropdown>
            </div>
          </div>
          <div class="ml-3">
            <div class="" style="height: 40%">
              <div class="w-100 py-4">
                <w-white-dropdown
                  :options="roiRangeDropdown"
                  :placeHolder="'Select'"
                  :labelText="'ROI Range'"
                  :selectedOption="selectedRoiRange"
                  @input="getSelectedROI($event)"
                ></w-white-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
      </w-tab>
      <div v-if="activeTab === 'Attribution'">
        <div class="mt-5" :class="breakDown ? 'card' : ''">
          <b-row class="no-gutters">
            <b-col lg="4" :class="breakDown ? '' : 'card'">
              <div>
                <pie-chart
                  title="Overall"
                  :colors="pieChartColor"
                  :series="overallSeries"
                  :showBreakdown="true"
                  :breakdown="breakDown"
                  @getBreakdown="getBreakdown"
                />
              </div>
            </b-col>
            <b-col lg="8" v-if="breakDown" class="breakdownable-card">
              <multiple-donut-chart
                :series1="externalData"
                :series2="internalData"
                :title="multipleTitle"
              />
            </b-col>
          </b-row>
        </div>
        <!--<div class="card mt-5 p-5">
          <b-container fluid>
            <Digital
              :data="digitalExpandData"
              :stripData="digitalData"
              @getDataBreakdown="getDigitalBreakdown"
            />
          </b-container>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Content
              :barData="contentExpandData"
              :stripData="contentData"
              @getDataBreakdown="getContentBreakdown"
            />
          </b-container>
        </div>-->
        <MultiLevelChart
          ref="multiLevelChart"
          :chartData="mediaChartData"
          :dropdownList="mediaDropdownList"
          :expandData="mediaExpandData"
          :selectedOption="selectedMediaDropdown"
          :text="'Media'"
          class="p-0"
          @selectedOption="getSelectedMediaDropdown($event)"
          @chartClick="getMediaBreakDown($event)"
          @finalSelectedOption="getSelectedChannelName($event)"
          @finalChartClick="getFinalChartClick($event)"
          :finalDropDownList="channelDropDownList"
          :finalColumnData="finalColumnData"
          :finalSelectedDropOption="selectedChannelOption"
          :annotation="'%'"
          :secondAnnotation="'%'"
          :thirdAnnotation="'%'"
        />
        <div
          class="card mt-5 p-5 d-none"
          v-for="(data, index) in attributionData"
          :key="index"
        >
          <b-container fluid>
            <Content
              :title="data.title"
              ref="solutionModal"
              :barData="data.expandData"
              :stripData="data.stripData"
              :breakeDownShow="data.breakDown"
              :showLegend="true"
              @getDataBreakdown="getBreakdownData($event, index)"
            />
          </b-container>
        </div>
      </div>
      <w-summary v-if="activeTab === 'ROI Summary'"></w-summary>
      <w-future-planning
        v-if="activeTab === 'Future Planning'"
      ></w-future-planning>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
import PieChart from "@/components/Chart/PieChart.vue";
// import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
import ROISummary from "./Summary.vue";
import FuturePlanning from "./FuturePlanning.vue";
import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
const digitalPoemService = new DigitalPoemServices();
import { AIDigitalPoem } from "@/services/AIDigitalPoemServices";
const aiDigitalPoem = new AIDigitalPoem();
import { abbreviateNumber } from "js-abbreviation-number";
import MultiLevelChart from "../../components/Chart/MediaWiseChart.vue";

export default {
  name: "Insights",
  components: {
    MultiLevelChart,
    "w-summary": ROISummary,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    PieChart,
    // Digital,
    Content,
    MultipleDonutChart,
  },
  data() {
    return {
      mediaDropdownList: [],
      mediaLevelTwoData: [],
      mediaFinalLevelData: [],
      channelDropDownList: [],
      selectedMediaDropdown: {},
      selectedChannelOption: {},
      mediaChartData: {
        data: [
          {
            name: "Media",
            data: [],
          },
        ],
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.$refs.multiLevelChart.clickedExpand(data.point.category);
                },
              },
            },
          },
        },
        color: ["#40c4ff", "#ffab40"],
        subtitle: {},
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      finalColumnData: {
        data: [
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
        },
        color: ["#40c4ff", "#ffab40"],
        subtitle: {},
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      mediaExpandData: {
        data: [
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.$refs.multiLevelChart.clickedFinalExpand(
                    data.point.category
                  );
                },
              },
            },
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: { enabled: false },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      selectedRoiRange: { text: "Overall", id: "0" },
      roiRangeDropdown: [
        { text: "Overall", id: "0" },
        { text: "1 Month", id: "1" },
        { text: "3 Month", id: "3" },
        { text: "6 Month", id: "6" },
        { text: "One Year", id: "12" },
      ],
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
      colorList: [
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
      attributionData: [
        {
          title: "",
          stripData: [],
          expandData: {
            yAxis: [
              {
                lineWidth: 1,
                title: {
                  text: null,
                },
              },
              {
                min: 0,
                max: 100,
                title: {
                  text: "",
                  align: "high",
                },
                gridLineDashStyle: "shortdash",
                labels: {
                  overflow: "justify",
                  style: {
                    color: "#8394b4",
                  },
                  formatter: function() {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return label + "%";
                  },
                },
                opposite: true,
                tickInterval: 25,
              },
            ],
            tooltip: {
              valueSuffix: "",
            },
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              itemStyle: {
                color: "#8394b4",
              },
            },
            credits: {
              enabled: false,
            },
            colors: [
              "#ffd740",
              "#18ffff",
              "#69f0ae",
              "#ff9100",
              "#ff5252",
              "#1e88e5",
              "#5e35b1",
              "#00897b",
            ],
            title: {
              text: "",
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
              },
              showInLegend: true,
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            series: [],
            xAxis: {
              categories: [],
              title: {
                text: null,
              },
              labels: {
                enabled: false,
                style: {
                  color: "#8394b4",
                  fontSize: "16px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
          },
          allExpandData: [],
        },
      ],
      breakDown: false,
      colors: [
        "#ffd740",
        "#18ffff",
        "#69f0ae",
        "#ff9100",
        "#ff5252",
        "#1e88e5",
        "#5e35b1",
        "#00897b",
      ],
      pieChartColor: ["#ff4081", "#64ffda"],
      contentBreakDown: false,
      activeTab: "Attribution",
      activeFrom: 1,
      selectedMarket: "",
      tabs: [
        {
          category: "Attribution",
        },
        {
          category: "Future Planning",
        },
        // {
        //   category: "ROI Summary",
        // },
      ],
      marketDropdown: [],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [],
        },
      ],
      digitalData: [],
      contentData: [],
      externalData: [],
      internalData: [],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#8394b4",
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#ff5252",
          "#1e88e5",
          "#5e35b1",
          "#00897b",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      digitalExpandData: [],
      multipleTitle: [],
    };
  },

  created() {
    this.selectedMarket = JSON.parse(sessionStorage.getItem("activeModalName"));
    this.marketDropdown = [];
    this.marketDropdown = JSON.parse(sessionStorage.getItem("modalList"));
    // sessionStorage.setItem('runId','202110-0809-0503-8fef-4_ykbfqzxtqdiclezqnauk')
    this.getOverallChartData();
    this.getAllChartData();
    // if(sessionStorage.getItem("roiRange")=== "0"){
    this.selectedRoiRange.text = "Overall";
    this.selectedRoiRange.id = "0";
    // }else if(sessionStorage.getItem("roiRange")=== "12"){
    //   this.selectedRoiRange.text = "One Year"
    //   this.selectedRoiRange.id = "12"
    // }else{
    //   this.selectedRoiRange.text = sessionStorage.getItem("roiRange")+" Month"
    //   this.selectedRoiRange.id = sessionStorage.getItem("roiRange")
    // }
    // this.getExternalData();
    // sessionStorage.removeItem("edited");
    // this.getFirstLevelData("digitalData", "digital");
    // this.getFirstLevelData("contentData", "content");
  },
  methods: {
    getSelectedMediaDropdown(e) {
      if (e.id === this.selectedMediaDropdown.id) {
        return;
      }
      this.selectedMediaDropdown = e;
      this.getMediaBreakDown(e);
    },
    getSelectedChannelName(e) {
      if (e.id === this.selectedChannelOption.id) {
        return;
      }
      this.selectedChannelOption = e;
      this.getFinalChartClick(e);
    },
    getSelectedROI(e) {
      if (e.id === this.selectedRoiRange.id) {
        return;
      }
      this.selectedRoiRange = e;
      // sessionStorage.setItem("roiRange", e.id);
      this.getOverallChartData();
      this.getAllChartData();
    },
    getBreakdownData(e, index) {
      this.attributionData[index].expandData.series = [];
      this.attributionData[index].breakDown = true;
      var expandDataObj = this.attributionData[index].allExpandData.find(
        (obj) => obj.title.toLowerCase() === e.label.toLowerCase()
      ).value;
      if (expandDataObj !== undefined) {
        console.log(expandDataObj);
        for (var i = 0; i < expandDataObj.length; i++) {
          console.log(expandDataObj[i]);
          this.attributionData[index].expandData.series.push({
            name: expandDataObj[i].name,
            data: [this.orgnizeNumber(expandDataObj[i].value * 100)],
            pointWidth: 16,
            yAxis: 1,
          });
        }
      }
    },
    getFirstLevelData(dataVariable, param) {
      var color = [];
      if (param === "content") {
        color = ["#fb8c00", "#ffb74d"];
      } else {
        color = [
          "#8e24aa",
          "#8e24aa",
          "#ab47bc",
          "#ce93d8",
          "#e1bee7",
          "#8e24aa",
          "#8e24aa",
          "#ab47bc",
          "#ce93d8",
          "#e1bee7",
        ];
      }
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          param,
          this.selectedMarket.id
        )
        .then((res) => {
          this[dataVariable] = [];
          for (var i = 0; i < res.length; i++) {
            this[dataVariable].push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: color[i],
            });
          }
        });
    },
    getExternalData() {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          "external factors",
          this.selectedMarket.id
        )
        .then((res) => {
          this.externalData = [];
          for (var i = 0; i < res.length; i++) {
            this.externalData.push({
              name: res[i].name,
              y: res[i].value,
              z: 92.9,
              dataLabels: {
                formatter: function() {
                  return this.y > 1
                    ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                    : null;
                },
              },
              id: "id" + i,
            });
          }
        });
    },
    getOverallChartData() {
      aiDigitalPoem
        .getChartData(
          {
            uniqueId: sessionStorage.getItem("runId"),
            model: this.selectedMarket.id,
            roiRange: this.selectedRoiRange.id,
          },
          // {
          //   uniqueId: "202110-0109-2456-e2fd-4_dwhetfmmmyiyjoznbwib",
          //   model: "dnnRegressor",
          // },
          "Attribution",
          "InternalExternal"
        )
        .then((res) => {
          this.overallSeries[0].data = [];
          for (var i = 0; i < res.response.levelOneData.length; i++) {
            if (i === 0) {
              this.overallSeries[0].data.push({
                name: res.response.levelOneData[i].name,
                y: this.orgnizeNumber(res.response.levelOneData[i].value * 100),
                sliced: true,
              });
            } else {
              this.overallSeries[0].data.push({
                name: res.response.levelOneData[i].name,
                y: this.orgnizeNumber(res.response.levelOneData[i].value * 100),
              });
            }
          }
          this.multipleTitle = [];
          for (i = 0; i < res.response.levelTwoData.length; i++) {
            if (res.response.levelTwoData[i].title === "External") {
              this.multipleTitle.push("External");
              this.externalData = [];
              for (
                var j = 0;
                j < res.response.levelTwoData[i].value.length;
                j++
              ) {
                this.externalData.push({
                  name: res.response.levelTwoData[i].value[j].name,
                  y: res.response.levelTwoData[i].value[j].value * 100,
                  z: 92.9,
                  dataLabels: {
                    formatter: function() {
                      return this.y > 1
                        ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                        : null;
                    },
                  },
                  id: "id" + j,
                });
              }
            } else if (res.response.levelTwoData[i].title === "Internal") {
              this.multipleTitle.push("Internal");
              this.internalData = [];
              for (j = 0; j < res.response.levelTwoData[i].value.length; j++) {
                this.internalData.push({
                  name: res.response.levelTwoData[i].value[j].name,
                  y: Math.round(
                    res.response.levelTwoData[i].value[j].value * 100
                  ),
                  z: 92.9,
                  dataLabels: {
                    formatter: function() {
                      return this.y > 1
                        ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                        : null;
                    },
                  },
                  id: "id" + j,
                });
              }
            }
          }
          this.breakDown = true;
        });
    },
    getAllChartData() {
      aiDigitalPoem
        .getChartData(
          {
            uniqueId: sessionStorage.getItem("runId"),
            model: this.selectedMarket.id,
            roiRange: this.selectedRoiRange.id,
          },
          "Attribution",
          "DigitalMedia"
        )
        .then((res) => {
          this.mediaLevelTwoData = [];
          this.attributionData = [];
          this.mediaChartData.data[0].data = [];
          this.mediaChartData.xAxis.categories = [];
          this.mediaDropdownList = [];
          for (var i = 0; i < res.response.length; i++) {
            this.mediaChartData.data[0].data.push(
              res.response[i].percentage_total
            );
            this.mediaChartData.xAxis.categories.push(res.response[i].title);
            this.mediaDropdownList.push({
              text: res.response[i].title,
              id: res.response[i].title,
              iconHTML: '<i class="fab fa-tag"></i>',
            });
            this.mediaLevelTwoData.push({
              name: res.response[i].title,
              value: res.response[i].levelOneData,
              levelTwoData: res.response[i].levelTwoData,
            });
          }
          // for (var i = 0; i < res.response.length; i++) {
          //   var stripData = [];
          //   for (var j = 0; j < res.response[i].levelOneData.length; j++) {
          //     stripData.push({
          //       label: res.response[i].levelOneData[j].name,
          //       value: this.orgnizeNumber(
          //         res.response[i].levelOneData[j].value * 100
          //       ),
          //       backgroundColor: this.colorList[j],
          //       symbol: "%",
          //     });
          //     if (j === res.response[i].levelOneData.length - 1) {
          //       this.attributionData.push({
          //         title: res.response[i].title,
          //         stripData: stripData,
          //         expandData: {
          //           yAxis: [
          //             {
          //               lineWidth: 1,
          //               title: {
          //                 text: null,
          //               },
          //             },
          //             {
          //               min: 0,
          //               max: 100,
          //               title: {
          //                 text: "",
          //                 align: "high",
          //               },
          //               gridLineDashStyle: "shortdash",
          //               labels: {
          //                 overflow: "justify",
          //                 style: {
          //                   color: "#8394b4",
          //                 },
          //                 formatter: function() {
          //                   var label = this.axis.defaultLabelFormatter.call(
          //                     this
          //                   );
          //                   return label + "%";
          //                 },
          //               },
          //               opposite: true,
          //               tickInterval: 25,
          //             },
          //           ],
          //           tooltip: {
          //             valueSuffix: "",
          //           },
          //           legend: {
          //             layout: "horizontal",
          //             align: "left",
          //             verticalAlign: "bottom",
          //             itemStyle: {
          //               color: "#8394b4",
          //             },
          //           },
          //           credits: {
          //             enabled: false,
          //           },
          //           colors: [
          //             "#ffd740",
          //             "#18ffff",
          //             "#69f0ae",
          //             "#ff9100",
          //             "#ff5252",
          //             "#1e88e5",
          //             "#5e35b1",
          //             "#00897b",
          //           ],
          //           title: {
          //             text: "",
          //           },
          //           plotOptions: {
          //             bar: {
          //               dataLabels: {
          //                 enabled: false,
          //               },
          //             },
          //             showInLegend: true,
          //             series: {
          //               borderRadiusTopLeft: 25,
          //               borderRadiusTopRight: 25,
          //             },
          //           },
          //           series: [],
          //           xAxis: {
          //             categories: [],
          //             title: {
          //               text: null,
          //             },
          //             labels: {
          //               enabled: false,
          //               style: {
          //                 color: "#8394b4",
          //                 fontSize: "16px",
          //                 fontFamily: "ProximaNovaRegular",
          //               },
          //             },
          //           },
          //         },
          //         breakDown: false,
          //         allExpandData: res.response[i].levelTwoData,
          //       });
          //     }
          //   }
          // }
        });
    },
    getMediaBreakDown(e) {
      this.selectedMediaDropdown = e;
      var data = this.mediaLevelTwoData.find((obj) => obj.name === e.id).value;
      this.mediaFinalLevelData = this.mediaLevelTwoData.find(
        (obj) => obj.name === e.id
      ).levelTwoData;
      this.mediaExpandData.data[0].data = [];
      this.mediaExpandData.xAxis.categories = [];
      this.mediaExpandData.data[0].name = this.selectedMediaDropdown.text;
      this.finalColumnData.data[0].data = [];
      this.finalColumnData.xAxis.categories = [];
      this.channelDropDownList = [];
      for (var i = 0; i < data.length; i++) {
        this.mediaExpandData.data[0].data.push(data[i].value);
        this.mediaExpandData.xAxis.categories.push(data[i].name);
        this.channelDropDownList.push({
          text: data[i].name,
          id: data[i].name,
          iconHTML: '<i class="fab fa-tag"></i>',
        });
      }
    },
    getFinalChartClick(e) {
      this.selectedChannelOption = e;
      this.finalColumnData.data[0].name = e.text;
      var data = this.mediaFinalLevelData.find((obj) => obj.title === e.text)
        .value;
      this.finalColumnData.data[0].data = [];
      this.finalColumnData.xAxis.categories = [];
      for (var i = 0; i < data.length; i++) {
        this.finalColumnData.data[0].data.push(data[i].value);
        this.finalColumnData.xAxis.categories.push(data[i].name);
      }
    },
    changeTab(tabName) {
      console.log(tabName);
      this.activeTab = tabName;
    },
    getContentBreakdown(data) {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.contentExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.contentExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getDigitalBreakdown(data) {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.digitalExpandData = [];
          var count = 0;
          for (var i = 0; i < res.length; i++) {
            this.digitalExpandData.push({
              name: res[i].name,
              pointWidth: 20,
              data: [
                {
                  x: count,
                  x2: count + res[i].value,
                  y: i + 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            });
            count += res[i].value;
          }
        });
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
    },
    getModalSummary() {
      aiDigitalPoem
        .getAllModals({ uniqueId: sessionStorage.getItem("runId"), model: "" })
        .then((res) => {
          var salseStatics = [];
          for (var i = 0; i < res.response.values.length; i++) {
            if (this.selectedMarket.id === res.response.values[i].title) {
              for (var j = 0; j < res.response.values[i].statics.length; j++) {
                salseStatics.push({
                  name: res.response.values[i].statics[j].name,
                  value: this.orgnizeNumber(
                    res.response.values[i].statics[j].value
                  ),
                  className: this.classess[j],
                });
              }
            }
          }
          sessionStorage.setItem("selectedPlan", JSON.stringify(salseStatics));
          this.getOverallChartData();
          this.getAllChartData();
        });
    },
    selectMarket(e) {
      if (e.id === this.selectedMarket.id) {
        return;
      }
      this.getModalSummary();
      sessionStorage.setItem("activeModalName", JSON.stringify(e));
      this.selectedMarket = e;
      this.breakDown = false;
    },
  },
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data);
        } else {
          return Math.round(data);
        }
      };
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  margin-top: 71px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
