<template>
  <div>
    <div class="container-fluid">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex w-100" style="justify-content: space-between;">
              <h5 class="sales-growth">Set Sales Growth</h5>
              <div
                class=""
                v-if="!isOptimizationDone"
                style="font-size: small; color: red;"
              >
                <span>Optimozation is inprogress. </span>
              </div>
              <div class="" style="font-size: small; color: red;">
                <span>{{ errrorMessage }}</span>
              </div>
              <div v-if="activeSaleGrowthTab === 'Budget Based'">
                <div
                  class=""
                  v-if="sliderValue > 40"
                  style="font-size: small; color: red;"
                >
                  <span>Please Select value from 0 to 40</span>
                </div>
              </div>
              <div v-if="activeSaleGrowthTab === kpiMarket + ' Based'">
                <div
                  class=""
                  v-if="kpiSliderValue > 40"
                  style="font-size: small; color: red;"
                >
                  <span>Please Select value from 0 to 40</span>
                </div>
              </div>
            </div>
            <w-tab
              :tabName="activeSaleGrowthTab"
              :tabs="saleGrowthtabs"
              @activeTab="changeSaleGrowthTab($event)"
            >
            </w-tab>
          </div>
        </div>

        <SalesGrowth
          v-show="activeSaleGrowthTab === 'Budget Based'"
          :sliderValue="sliderRevenueValue"
          :data="revenueData"
          :step="5"
          @sliderChangeHandle="revenueSliderValue"
          :handleColor="'#536dfe'"
          :sliderName="'Budget Growth'"
          :specialChar="''"
          :loader="showLoader"
        />
        <SalesGrowth
          v-show="activeSaleGrowthTab === kpiMarket + ' Based'"
          :sliderValue="sliderKPISelectionValue"
          :data="kpiSelectionData"
          :step="5"
          @sliderChangeHandle="getKpiSliderValue"
          :handleColor="'#536dfe'"
          :sliderName="this.kpiMarket+' Growth'"
          :specialChar="''"
          :loader="showLoader"
        />
      </div>

      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12">
            <details class="">
              <Summary class="Additional-details">Additional Details</Summary>
              <div
                class="row align-items-center additonal-detail pt-5"
                v-for="(detail, index) in additionalDetails"
                :key="index"
              >
                <div class="col-lg-2 col-md-2 col-12">
                  <h6 class="ProductNew">{{ detail.name }}</h6>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="d-flex justify-center">
                    <div class="w-100">
                      <w-dropdown
                        :options="detail.dropdownList"
                        :placeHolder="'Select'"
                        :labelText="'Product'"
                        class=""
                        @input="selectedKPi($event, index)"
                      ></w-dropdown>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-12">
                  <div class="date-wraper d-flex w-100">
                    <div class="d-flex align-items-center w-100">
                      <w-datepicker
                        :selectedDate="detail.date"
                        :placeHolder="'Select'"
                        :labelText="'Date'"
                        class=""
                        @getDate="selectedProduct($event, index)"
                      ></w-datepicker>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-12">
                  <div>
                    <b-form-input
                      class="input-wrapper"
                      v-model="detail.value"
                      type="number"
                      placeholder="Value in numbers"
                      oninput="value=value.replace(/[^0-9]/g,'');"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </details>
          </div>
        </div>
      </div>

      <div class="row w-100 m-0" style="justify-content: flex-end">
        <w-button
          style="width: 186px"
          :buttonLoader="'normal'"
          :buttonText="'Predict'"
          :isDisable="disablePredict || !showLoader"
          class="my-5"
          @buttonClicked="handlePredict"
          :class="{ resetPasswordLink: disablePredict || !showLoader }"
        >
        </w-button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import SalesGrowth from "@/components/Solutions/SalesGrowth.vue";
import DatePicker from "@/widgets/DatePicker.vue";
import { AIDigitalPoem } from "../../services/AIDigitalPoemServices";
import { abbreviateNumber } from "js-abbreviation-number";
const aiDigitalPoem = new AIDigitalPoem();
var timeOut;
export default {
  props: [],
  components: {
    "w-button": Button,
    "w-tab": Tab,
    "w-dropdown": Dropdown,
    SalesGrowth,
    "w-datepicker": DatePicker,
  },
  data() {
    return {
      disablePredict: false,
      categorizedSelectedConnectors: [],
      kpiMarket: "",
      // stepName: ["Platform", "DataSource", "KPI"],
      // saleGrowthtabs: [
      //   {
      //     category: "Budget Based",
      //   },
      //   {
      //     category: this.kpiMarket + " Based",
      //   },
      // ],
      activeSaleGrowthTab: "Budget Based",
      errrorMessage: "",
      sliderRevenueValue: 0,
      sliderKPISelectionValue: 0,
      revenueData: [
        { name: "Last year Revenue", value: 0 },
        { name: "Expected Revenue", value: 0 },
      ],
      kpiSelectionData: [
        { name: "Last year KPI", value: 0 },
        { name: "Expected KPI", value: 0 },
      ],
      sliderBudgetValue: 0,
      budgetData: [
        { name: "Last year Budget", value: 0 },
        { name: "Expected Budget", value: 0 },
      ],
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          selectedValue: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "Modern Trade", id: "Modern Trade" },
            { text: "Traditional Trade", id: "Traditional Trade" },
            { text: "Online/Market Places", id: "Online/Market Places" },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            {
              text: "New Consumer Promo Campaigns",
              id: "New Consumer Promo Campaigns",
            },
            { text: "Total Consumer Promos", id: "Total Consumer Promos" },
            { text: "Trade Promos", id: "Trade Promos" },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New Messaging Platforms", id: "New Messaging Platforms" },
            { text: "New Campaign Lunches", id: "New Campaign Lunches" },
            {
              text: "New Celebrity endorsements",
              id: "New Celebrity endorsements",
            },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
      ],
      optimizeStatus: "",
      isOptimizationDone: false,
      optimizationData: false,
      sliderValue: 0,
      kpiSliderValue: 0,
    };
  },
  computed: {
    showLoader() {
      if (this.isOptimizationDone && this.optimizationData) {
        return true;
      }
      return false;
    },
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1);
        } else {
          return Math.round(data * 10) / 10;
        }
      };
    },
    saleGrowthtabs() {
      return [
        {
          category: "Budget Based",
        },
        {
          category: this.kpiMarket + " Based",
        },
      ];
    },
  },
  methods: {
    selectedKPi(e, index) {
      if (this.additionalDetails[index].selectedValue != e) {
        this.additionalDetails[index].selectedValue = e;
        console.log(e);
      }
    },
    revenueSliderValue(e) {
      this.sliderValue = e;
      if (e > 40 || isNaN(e)) {
        this.disablePredict = true;
        return;
      }
      if (!this.isOptimizationDone) {
        return;
      }
      this.disablePredict = false;
      this.sliderRevenueValue = e;
      this.getClientData();
    },
    getKpiSliderValue(e) {
      this.kpiSliderValue = e;
      if (e > 40 || isNaN(e)) {
        this.disablePredict = true;
        return;
      }
      if (!this.isOptimizationDone) {
        return;
      }
      this.disablePredict = false;
      this.sliderKPISelectionValue = e;
      this.getClientDataKPI();
    },
    changeSaleGrowthTab(tabName) {
      this.activeSaleGrowthTab = tabName;
    },
    handlePredict() {
      if (this.activeSaleGrowthTab === "Budget Based") {
        sessionStorage.setItem("targetVariable", "budget");
        sessionStorage.setItem("actualGrowth", this.sliderRevenueValue);
      } else if (this.activeSaleGrowthTab === this.kpiMarket + " Based") {
        sessionStorage.setItem("targetVariable", "KPI");
        sessionStorage.setItem("actualGrowth", this.sliderKPISelectionValue);
      }
      sessionStorage.setItem("edited", "notEdited");
      this.$router.push("/solutions/digital-poem/planning-result");
    },
    selectedProduct(e, index) {
      this.additionalDetails[index].date = e;
    },
    getClientData() {
      this.optimizationData = false;
      if (!this.isOptimizationDone) {
        return;
      }
      aiDigitalPoem
        .getExpectedRevenue(
          sessionStorage.getItem("runId"),
          this.sliderRevenueValue,
          "budget"
        )
        .then((res) => {
          this.optimizationData = true;
          this.revenueData = [];
          this.revenueData.push({
            name: "Previous Budget",
            value: this.orgnizeNumber(
              res.response.result.kpiSelection.previous
            ),
          });
          this.revenueData.push({
            name: "Optimized Budget",
            value: this.orgnizeNumber(
              res.response.result.kpiSelection.expected
            ),
          });
        })
        .catch(() => {
          this.errrorMessage =
            "Something Went Wrong. Please try after sometime.";
        });
    },
    getClientDataKPI() {
      this.optimizationData = false;
      if (!this.isOptimizationDone) {
        return;
      }
      aiDigitalPoem
        .getExpectedRevenue(
          sessionStorage.getItem("runId"),
          this.sliderKPISelectionValue,
          "KPI"
        )
        .then((res) => {
          this.kpiMarket = res.response.result.kpiConfig
          this.optimizationData = true;
          this.kpiSelectionData = [];
          this.kpiSelectionData.push({
            name: "Previous "+this.kpiMarket,
            value: this.orgnizeNumber(
              res.response.result.kpiSelection.previous
            ),
          });
          this.kpiSelectionData.push({
            name: "Target "+this.kpiMarket,
            value: this.orgnizeNumber(
              res.response.result.kpiSelection.expected
            ),
          });
        })
        .catch(() => {
          this.errrorMessage =
            "Something Went Wrong. Please try after sometime.";
        });
    },
    getOptimizationStatus() {
      clearTimeout(timeOut);
      aiDigitalPoem
        .getStatus(sessionStorage.getItem("runId"), "optimisation")
        .then((res) => {
          this.optimizeStatus = res.status;
          var self = this;
          if (res.status.optimization.status !== "ended") {
            this.isOptimizationDone = false;
            timeOut = setTimeout(function() {
              self.getOptimizationStatus();
            }, 2000);
          } else {
            this.isOptimizationDone = true;
            this.getClientData();
            this.getClientDataKPI();
            clearTimeout(timeOut);
          }
        });
    },
  },
  created() {
    var growth = parseInt(sessionStorage.getItem("actualGrowth"));
    this.kpiMarket = sessionStorage.getItem("kpiConfig");
    sessionStorage.setItem("edited", "notEdited");
    console.log(growth);
    if (growth !== undefined && growth !== null && !isNaN(growth)) {
      this.sliderRevenueValue = growth;
      this.revenueSliderValue(growth);
      this.sliderKPISelectionValue = growth;
      this.getKpiSliderValue(growth);
    } else {
      this.sliderRevenueValue = 0;
      this.sliderValue = 0;
      this.sliderKPISelectionValue = 0;
      this.kpiSliderValue = 0;
    }
    if (sessionStorage.getItem("targetVariable") === "budget") {
      this.activeSaleGrowthTab = "Budget Based";
    } else if (sessionStorage.getItem("targetVariable") === "KPI") {
      this.activeSaleGrowthTab = this.kpiMarket + " Based";
    }

    this.getOptimizationStatus();
  },
};
</script>
<style scoped>
>>> .rs-handle.rs-move {
  border: 11px solid #fff;
}
.content-wrapper {
  margin-top: 24px;
  padding: 28px 23px 27px 24px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.date-wraper {
  height: 60px;
  background-color: #eff5ff;
}

.date-label-wrapper {
  width: 40%;
}

.date-label {
  margin: 0px 16px;
  padding: 6px 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #313846;
}

.date-label {
  margin: 0px 16px;
  padding: 6px 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #313846;
}

.line {
  width: 2px;
  height: 29px;
  background: #c6ccd6;
}

.date-input {
  height: 60px;
  width: 100%;
  font-size: 16px;
  padding: 0 20px;
}

.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}

input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
}

input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
}

input[type="number"] {
  outline: none;
  box-shadow: none;
  font-family: ProximaNovaBold;
  font-size: 16px;
}

.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.card {
  display: block;
}

.future {
  width: 144px;
  height: 24px;
  margin: 0 48px 7px 180px;
  /* margin-left: 500px; */
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
}

.Path-8 {
  /* width: 40px;
  height: 1px; */
  margin: 0px 0 0 230px;
  /* object-fit: contain;
  border: solid 2px #050505; */
}

/* .Path-8new {
  margin: 0px 0 0 550px;
} */

/* .roi {
  width: 117px;
  height: 24px;
  margin: 0 0 7px -100px;
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
} */

.sales-growth {
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
}
input::-webkit-inner-spin-button {
  display: none;
}
.Additional-details {
  width: 259px;
  height: 24px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  outline: none;
}

.ProductNew {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.Additional-details::marker {
  display: flex;
  float: right;
  line-height: center;
  justify-content: center;
}
</style>
